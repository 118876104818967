@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;700&display=swap");

.home {
  font-family: Work Sans;
  scroll-behavior: smooth;
  font-size: 18px;

  h3 {
    font-weight: 700;
  }

  .hero {
    position: relative;
    color: #fff;

    .hero-bg {
      position: absolute;
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    &_inner {
      padding-top: 4em;
      padding-bottom: 4em;

      .hero-desc {
        text-transform: uppercase;
        h1 {
          font-size: 60px;
        }

        @media screen and (max-width: 992px) {
          margin-bottom: 2em;
        }
      }

      .hero-form {
        flex: 1;
        height: 85vh;

        #form-frame {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }

      @media screen and (min-width: 992px) {
        display: flex;
        align-items: center;
        gap: 2em;
        .hero-desc {
          align-self: flex-end;
          max-width: 40%;
        }
      }
    }
  }

  .location-info {
    background: #37761d;
    color: #fff;
    padding-top: 5em;
    padding-bottom: 5em;
    line-height: 1.5rem;
    &_inner {
      .nearby,
      .rera-number {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  footer {
    color: #050533;
    font-family: Work Sans;
  }
}
